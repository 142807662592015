import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import privacyPolicy from "../images/privacyPolicy/banner.png"

const PrivacyPolicy = () => {
  return (
    <Layout pageName="Privacy Policy">
      <SEO title="Privacy Policy" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={privacyPolicy} />
        </div>
        <div className="col-lg-6 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">Things to know</p>
              <p className="fi-head mb-lg-3 mb-3">PRIVACY POLICY</p>
              <p className="fi-txt  mb-lg-3 mb-4">
                Teach For India is committed to providing privacy for visitors
                on our website as well as recipients of our email messages.
                Teach For India promotes the tenets of opt-in email and privacy
                among our partners and employees. We are continuously evaluating
                our internal procedures and technology to ensure privacy at all
                levels in our organization.
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Container className="py-lg-5 my-lg-5">
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Information Tracked</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              We track location, device type, browser type to help us understand
              our visitors’ needs related to our website design. We do so in a
              way that makes your personal information non-personally
              identifiable by aggregating data and removing identifiable
              characteristics.
            </p>
            <p className="fi-txt">
              There are registration and application sections on our website
              where input forms require your name, email address and other
              information. By submitting such forms, you give us permission to
              send email messages to you containing information relating to your
              interest in Teach For India. Each email we send will also contain
              instructions on how to opt-out of receiving future emails of any
              sort from Teach For India.
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Cookies</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              Client-side cookies are used to verify the login status of
              visitors and registered users of our website. Usage of a cookie is
              in no way linked to any personally identifiable information while
              on our site. If visitors reject a cookie, they may still use our
              site; however, they may not be able to log in, or access all areas
              of our site. (Cookies are text files our website places in your
              computer’s browser to store your preferences.)
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Security and Privacy</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              We will never share, sell, or rent your individual personal
              information with or to any individual or organization without your
              advance permission or unless ordered by a court of law.
              Information submitted to us is only available to employees
              managing this information for purposes of contacting you,
              processing your application, or sending you emails related to your
              interest in Teach For India.
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Links to other websites</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              Teach For India is not responsible for the privacy policies or the
              content of any other websites we link you to.
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Choice/Opt-out</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              Our e-newsletter subscribers and website visitors are given the
              opportunity to opt-out of having their information used for
              purposes not directly related to our site at the point where we
              ask for the information. Users of our site will always be notified
              if their information is being collected by any outside parties. We
              do this to ensure our users can make an informed choice as to
              whether they should proceed with services that require an outside
              party, or not.
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Third party agreement</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              This site works with third-party services that collect anonymous
              traffic data from this site.
            </p>
          </div>
        </Row>
        <Row className="borderBottomBlack">
          <div className="col-lg-4">
            <p className="ed-head">Notification of changes</p>
          </div>
          <div className="col-lg-8">
            <p className="fi-txt">
              If we change our privacy policy, we will post these changes to
              ensure that our users are always aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              disclose it. If at any point we decide to use personally
              identifiable information in a manner different from that stated at
              the time it was collected, we will notify users by way of an email
              at the last address provided and/or by prominently posting a
              notice of the changes on our website. Users will have a choice as
              to whether or not we use their information in this different
              manner. We will use information in accordance with the privacy
              policy under which information was collected. Any such changes may
              affect our use and sharing of personal information that you
              provided to us before our notification to you of the changes. If
              you do not wish to permit changes in our collection, use, or
              sharing of personal information that you have provided to us
              before the effective date of such changes, you must notify us
              before that effective date. Please be advised, however, that
              regardless of whether you provide such notice to us, the changes
              to this privacy policy will apply to any personal information that
              you provide to us on or after the effective date of such changes.
            </p>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy
